<template >
	<div class="expired-container" v-if="coc_errors">
		<img
			src="/Images/errors/error.png"
			class="card-img"
			v-if="[402, 410, 423].includes(coc_errors.code)"
		/>
		<img
			src="/Images/errors/maintenanceplan.png"
			class="card-img"
			v-if="coc_errors.code == 503"
		/>
		<h3 class="expired-title" v-if="coc_errors.title">
			{{ coc_errors.title }}
		</h3>
		<p class="expired-message">{{ coc_errors.message }}</p>

		<a
			class="text-white px-6 py-3 mt-5 font-semibold border border-white rounded-full hover:bg-white hover:text-blue-600"
			href="/"
			>Reload</a
		>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	data() {
		return {}
	},
	beforeCreate() {
		localStorage.removeItem('errors')
	},
	mounted() {
		// this.getCocErrors();
	},
	methods: {
		// getCocErrors: function () {
		// 	// localStorage.removeItem("errors");
		// 	let cocErrors = localStorage.getItem("errors");
		// 	if (cocErrors) {
		// 		cocErrors = JSON.parse(cocErrors);
		// 		this.coc_errors = {
		// 			message: cocErrors.message,
		// 			title: cocErrors.title,
		// 			code: cocErrors.code,
		// 		};
		// 	}
		// },
	},
	computed: mapState({
		coc_errors: (state) => state.cocErrors.coc_errors,
	}),
}
</script>

<style scoped>
.expired-container {
	width: 100%;
	min-height: 100vh;
	background: linear-gradient(
			to right,
			rgb(137, 104, 255),
			rgb(175, 152, 255)
		),
		url('/Images/errors/school-bg.svg');
	background-blend-mode: multiply;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 20px;
	position: absolute;
	z-index: 999999999999999999;
	left: 0;
	top: 0;
}

.expired-title {
	margin: 25px 0 20px 0 !important;
	font-size: 25px;
	font-weight: 700;
	color: #fff;
	max-width: 800px;
	text-align: center;
}

.expired-message {
	color: #f0efef;
	font-size: 20px;
	line-height: 25px;
	max-width: 420px;
	text-align: center;
}

@media (min-width: 575px) {
	.card-img {
		width: 90%;
	}
}

@media (min-width: 767px) {
	.card-img {
		width: 60%;
	}
}

@media (min-width: 991px) {
	.card-img {
		width: 40%;
	}
}

@media (min-width: 1199px) {
	.card-img {
		width: 20%;
	}
}
</style>