import axios from 'axios'
import { ClientJS } from 'clientjs'
import $cookie from './cookie'
import router from "../router/index";
import store from "../store/index";

window.axios = axios;
var host = window.location.host
var baseURL;
let browserId = $cookie.getCookie('browser');
const cocauthorization = $cookie.getCookie('cocauthorization');

var subdomain = host.split('.')[0];
let whiteList = import.meta.env.VITE_WHITELIST_DOMAIN;


if (import.meta.env.VITE_APP_ENV == "production" || import.meta.env.VITE_APP_ENV == "staging") {

    if (whiteList.split(',').includes(location.hostname)) {
        let domain = location.hostname.replace('.student', '')
        baseURL = 'https://' + domain + '/api/v1';
    } else {
        baseURL = 'https://' + subdomain + '.' + import.meta.env.VITE_API_DOMAIN + '/api/v1';
    }

} else {
    baseURL = 'http://' + subdomain + '.' + import.meta.env.VITE_API_DOMAIN + '/api/v1';
}

if (!browserId) {
    const client = new ClientJS();
    browserId = client.getFingerprint();
    browserId = subdomain + '_' + browserId;
    $cookie.setCookie('browser', browserId, 120);
}

axios.defaults.baseURL = baseURL


if (window.ReactNativeWebView) {
    window.axios.defaults.headers.common['platfrom'] = 'App';
} else {
    window.axios.defaults.headers.common['platfrom'] = 'Web';
}

/* if (window != window.top) { 
    window.axios.defaults.headers.common['platfrom'] = 'App';
} */

window.axios.defaults.headers.common['version'] = '2.0.0';

window.axios.defaults.headers.common['device-id'] = browserId;
window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + cocauthorization;
window.axios.defaults.headers.common['Content-Type'] = 'application/json';

window.axios.defaults.xsrfCookieName = "XSRF-TOKEN";
window.axios.defaults.xsrfHeaderName = "X-XSRF-TOKEN";

window.axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {

    /* if (error.code == "ERR_NETWORK") {
        let errorData = {
            title: "Under Maintenance",
            message: "Service Unavailable",
            code: 503,
        };
        localStorage.setItem('errors', JSON.stringify(errorData));
        store.dispatch("cocErrors/changeStatus");
        return
    } */

    if (error.response && [402, 410, 423, 503].includes(error.response.status)) {
        let errorData = {
            message: error.response.data.message,
            title: error.response.data.title,
            code: error.response.status,
        };
        localStorage.setItem('errors', JSON.stringify(errorData));
        store.dispatch("cocErrors/changeStatus");
        return
    }

    if (error.response) {
        if (error.response.status == 401) {
            /* Unauthorized */
            if (cocauthorization) {
                if ((new URL(location.href)).pathname != '/') {
                    $cookie.deleteCookie('cocauthorization')
                    router.push({ name: "Login" })
                }
            }

        } else if (error.response.status == 422) {
            /* Validation */
        } else if (error.response.status == 403) {
            /* Permission */
        } else if (error.response.status == 404) {
            /* Not Found */
        } else if (error.response.status == 408) {
            /* Request time out */
        } else if (error.response.status == 503) {
            router.push({ name: "UnderMaintenance" })
        } else if (error.response.status == 423) {
            /* Payment Required  */
            let title = "Please Contact School Admin!"
            let message = "Please recharge first to continue using the app."
            if (error.response.data) {
                title = error.response.data.title
                message = error.response.data.message
            }
            router.push({ name: "RequestTimeOut", query: { title: title, message: message } })
        } else {
            /* let responseData = error.response;
            let config = responseData.config;
            let request = responseData.request;
            let data = {
                origin: host,
                status: responseData.status,
                baseURL: config.baseURL,
                browserId: browserId,
                url: config.url,
                headers: config.headers,
                cookies: document.cookie,
                data: config.data,
                method: config.method,
                statusText: responseData.message
            };
            fetch(subdomain + '/api/v1/error-log', {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                mode: "cors", // no-cors, *cors, same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached 
                headers: {
                    "Content-Type": "application/json",
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(data), // body data type must match "Content-Type" header
            }); */
        }
    } else {
        /* if (error.code = "ERR_NETWORK") {
            router.push({ name: "NetworkError" })
        } */
    }
    return Promise.reject(error);
}); 