<template>
	<div>
		<div class="page-reload" id="page-reload">
			<div class="flex justify-center items-center mx-auto">
				<Icon icon="fa-solid:redo-alt" class="text-lg" />
			</div>
		</div>

		<CocErrors></CocErrors>

		<router-view name="MasterLayount"></router-view>
		<router-view name="RouterLayount"></router-view>

		<FullPageLoader v-if="full_page_loading" />
		<div class="absolute right-5 top-5 z-20">
			<transition :name="accountChangeAni">
				<div v-if="accountChange && user_info">
					<div
						class="flex justify-between py-2 px-4 bg-white/60 backdrop-blur-xl rounded-lg shadow"
					>
						<div class="flex items-center space-x-4">
							<img
								:src="user_info.profile_image.thumbnail_132X172"
								class="rounded-full h-12 w-12"
								:alt="user_info.institute_info.name"
							/>
							<div class="flex flex-col space-y-1">
								<span class="font-bold">{{
									user_info.name
								}}</span>
								<span class="text-sm">Switch User</span>
							</div>
						</div>
						<!-- <div
						class="flex-none px-4 py-2 text-stone-600 text-xs md:text-sm"
					>
						17m ago
					</div> -->
					</div>
				</div>
			</transition>
		</div>
		<template v-if="appClosePopup">
			<div
				class="min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-black/70 transition-all"
				style="
					background-color: #000000b3;
					-webkit-backdrop-filter: blur(10px);
					backdrop-filter: blur(10px);
				"
			>
				<div class="bg-white rounded-lg">
					<div
						class="w-72 mobile:w-96 justify-center border-t-8 border-orange-500 rounded-lg flex"
					>
						<div class="pt-6">
							<div
								class="grid grid-cols-12 gap-4 place-items-center"
							>
								<div
									class="p-2 pt-3 w-20 col-span-12 pro-mobile:col-span-3"
								>
									<IconifyIcon
										class="w-16 h-16 text-orange-500 text-center"
										icon="material-symbols:app-shortcut"
									/>
								</div>
								<!-- ... -->
								<div
									class="p-2 pt-3 col-span-12 pro-mobile:col-span-9"
								>
									<h3
										class="font-bold text-orange-500 text-center pro-mobile:text-left"
									>
										Exit
									</h3>
									<p class="py-4 text-sm text-gray-500">
										Are you sure want to exit?
									</p>
								</div>
							</div>
						</div>
					</div>

					<div class="p-4 flex space-x-4 justify-center">
						<button
							@click="appCloseEvent('Close')"
							class="w-1/2 px-4 py-3 text-center bg-gray-100 text-gray-400 hover:bg-gray-200 hover:text-black font-bold rounded-lg text-sm"
						>
							Yes, Exit
						</button>
						<button
							@click="appCloseEvent('No')"
							class="w-1/2 px-4 py-3 text-center bg-gray-100 text-gray-400 hover:bg-gray-200 hover:text-black font-bold rounded-lg text-sm"
						>
							No
						</button>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import { Icon } from '@iconify/vue'
import { mapGetters, mapState, mapActions } from 'vuex'
import CocErrors from '@/components/CocErrors.vue'
import FullPageLoader from '@/components/FullPageLoader.vue'
import postMessage from '@/services/PostMessage'
export default {
	components: {
		Icon,
		CocErrors,
		FullPageLoader,
	},
	data() {
		return {
			accessToken: null,
			accountChange: false,
			accountChangeAni: 'slide-left',
			appClosePopup: false,
		}
	},
	beforeCreate() {
		localStorage.removeItem('nextLoginCheck')
	},
	mounted() {
		this.getPostMessage()
		this.accountChange = localStorage.getItem('notification-account-change')
		localStorage.removeItem('notification-account-change')
		let _startY
		let height = screen.height
		let refreshHeightStart = (height * 16) / 100
		let refreshHeight = (height * 15) / 100
		let refresh = 0
		var deg = 0
		const inbox = document.querySelector('body')
		const pageReload = document.getElementById('page-reload')

		inbox.addEventListener(
			'touchstart',
			(e) => {
				_startY = e.touches[0].pageY
				if (_startY < refreshHeightStart) {
				} else {
					refresh = 0
					_startY = 0
				}
			},
			{ passive: true }
		)

		inbox.addEventListener(
			'touchmove',
			(e) => {
				const y = e.touches[0].pageY

				if (
					_startY &&
					_startY < refreshHeightStart &&
					document.scrollingElement.scrollTop === 0
				) {
					if (refreshHeight < y) {
						refresh = 1
						pageReload.style.display = 'block'
						pageReload.style.top = y / 10 + 'px'
						deg = deg + 5
						pageReload.style.transform = 'rotate(' + deg + 'deg)'
					} else {
						refresh = 0
						pageReload.style.display = 'none'
						pageReload.style.top = 0
					}
				} else {
					refresh = 0
					pageReload.style.display = 'none'
					pageReload.style.top = 0
				}
			},
			{ passive: true }
		)

		inbox.addEventListener(
			'touchend',
			(e) => {
				let _endY = e.changedTouches[0].pageY

				if (_endY > _startY && refresh == 1) {
					window.location.reload()
				}
				pageReload.style.display = 'none'
				pageReload.style.top = 0
				refresh = 0
			},
			{ passive: true }
		)
		setTimeout(() => {
			this.accountChangeAni = 'slide-right'
			this.accountChange = false
		}, 10000)
	},
	unmounted() {},
	methods: {
		getPostMessage: function () {
			if (window.ReactNativeWebView) {
				window.addEventListener('message', (message) => {
					this.DeviceMessage(message)
				})
				document.addEventListener('message', (message) => {
					this.DeviceMessage(message)
				})
			}
		},
		DeviceMessage: function (message) {
			if (message.data && message.data != '') {
				let data = JSON.parse(message.data)
				let payload = data.payload
				if (data.action == 'ReAuth' && !this.accessToken) {
					window.axios.defaults.headers.common['device-id'] =
						payload.deviceID
					$cookie.setCookie('browser', payload.deviceID, 120)
					if (payload.userToken) {
						this.accessToken = payload.userToken
						// console.log('ReAuth Set In Cookie', this.accessToken)
						// alert(`Re Auth Set ${payload.userToken}`)

						if (payload.notification) {
							localStorage.removeItem('nextLoginCheck')
							let newUserToken = payload.notification.accessToken
							let onClick = payload.notification.onclick

							if (this.accessToken != newUserToken) {
								localStorage.setItem(
									'notificationRedirect',
									onClick
								)
								localStorage.setItem(
									'notification-account-change',
									true
								)
							}

							window.axios.defaults.headers.common[
								'Authorization'
							] = `Bearer ${newUserToken}`
							$cookie.setCookie(
								'cocauthorization',
								newUserToken,
								120
							)
							location.href = onClick
						} else {
							$cookie.setCookie(
								'cocauthorization',
								this.accessToken,
								120
							)
						}
					}
				}

				if (data.action == 'Notification') {
					localStorage.removeItem('nextLoginCheck')
					const cocAuthorization =
						$cookie.getCookie('cocauthorization')

					let newUserToken = payload.accessToken
					let onClick = payload.onclick
					if (cocAuthorization != newUserToken) {
						localStorage.setItem(
							'notification-account-change',
							true
						)
						axios
							.put('change-account', {
								token: newUserToken,
								notification: { redirect: onClick },
							})
							.then((response) => {
								$cookie.deleteCookie('cocauthorization')
								localStorage.removeItem('nextLoginCheck')
								let data = {
									action: 'Auth',
									payload: newUserToken,
								}
								postMessage.send(data)
								if (response.data.secret_url) {
									$cookie.setCookie(
										'cocauthorization',
										newUserToken,
										120
									)
									onClick = response.data.secret_url
								} else {
									$cookie.setCookie(
										'cocauthorization',
										newUserToken,
										120
									)
								}
								location.href = onClick
							})
					} else {
						localStorage.removeItem('notificationRedirect')
						location.href = onClick
					}
				}

				if (data.action == 'GoBack') {
					if (this.$route.meta.backPath) {
						this.$router.push({ name: this.$route.meta.backPath })
					} else {
						this.appClosePopup = true
					}
				}
			}
		},
		appCloseEvent: function (params) {
			if (params == 'Close') {
				let data = {
					action: 'AppClose',
					payload: null,
				}
				postMessage.send(data)
			} else {
				this.appClosePopup = false
			}
		},
	},
	updated() {
		// document.getElementById('full_page_load').style.display = 'none'
	},
	watch: {
		logo() {
			let recaptchaScript = document.createElement('link')
			recaptchaScript.setAttribute('rel', 'icon')
			recaptchaScript.setAttribute('href', this.logo)
			document.head.appendChild(recaptchaScript)
		},
		user_info() {
			if (this.user_info) {
				let recaptchaScript = document.createElement('link')
				recaptchaScript.setAttribute('rel', 'icon')
				recaptchaScript.setAttribute(
					'href',
					this.user_info.institute_info.logo
				)
				document.head.appendChild(recaptchaScript)
			}
		},
	},
	computed: {
		...mapState({
			full_page_loading: (state) => state.layout.full_page_loading,
			logo: (state) => state.login.login.logo,
			user_info: (state) => state.user.user_information,
		}),
	},
}
</script>
<style>
.page-reload {
	font-weight: bold;
	top: 0;
	height: 0;
	transition: height 0.3s, min-height 0.3s;
	text-align: center;
	width: 100%;
	overflow: hidden;
	position: absolute;
	min-height: 32px;
	z-index: 99999;
	display: none;
}

.page-reload div {
	background-color: var(--lightblue);
	border-radius: 50%;
	font-weight: bold;
	width: 30px;
	height: 30px;
	color: var(--primary);
}
</style>