export default {
    state: {
        coc_errors: null,
    },
    getters: {
    },
    actions: {
        async changeStatus(context) {
            let cocErrors = localStorage.getItem("errors");
            if (cocErrors) {
                cocErrors = JSON.parse(cocErrors);
            }
            context.commit("HasError", {
                message: cocErrors.message,
                title: cocErrors.title,
                code: cocErrors.code,
            })
        },
    },
    mutations: {
        HasError(state, data) {
            state.coc_errors = data
        },

    },
    namespaced: true
}